@function px2rem($px) {
  $rem: 14px;
  @return ($px/$rem)+rem;
}
.agree-title{
  font-weight: bold;
  font-size: px2rem(16px);
  margin: px2rem(16px);
}

.icon-health{
  width: px2rem(56px);
  height: px2rem(56px);
  border-radius: px2rem(8px);
  margin-left: 50%;
}
.icon-arrow{
  margin-top: px2rem(18px);
  width: px2rem(29px);
  height: px2rem(26px);
}
.icon-doctor{
  width: px2rem(56px);
  height: px2rem(56px);
  border-radius: px2rem(8px);
  margin-right: 50%;
}

.icon-img-bg{
  width: 100%;
  height: px2rem(192px);
  background-color: #f5f5f5;
  text-align: center;
}

.icon-img-box{
  display: flex;
  padding: px2rem(40px) 0 0 0;
  text-align: center;
  width: 100%;
  height: px2rem(112px);
}

.icon-img{
  flex: 1;
  text-align: center;
}

.icon-tip{
  width: px2rem(243px);

  margin: 0 auto;
}
.agree-tip{
  margin: px2rem(16px);
  font-size: px2rem(14px);
  color: #333333;
}

.inborder {
  position: absolute;
  width: px2rem(8px);
  height: px2rem(8px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #005BAC;
}

.exborder {
  display: inline-block;
  width: px2rem(15px);
  height: px2rem(15px);
  border: 2px solid #999999;
  border-radius: 50%;
  text-align: center;
  position: relative;
  vertical-align: middle;
}

.agree {
  padding: 0 px2rem(4px) 0 px2rem(10px);
}
.exborder-box{
  height: px2rem(200px);
  padding: px2rem(20px);
}

.login-btn {
  width: 100%;
  padding: px2rem(8px) px2rem(16px);
  margin-top: px2rem(16px);
  height: px2rem(44px);
  font-size: px2rem(17px);
  color: #fff;
  border-radius: px2rem(22px);
  background: #ccdeee;
  outline: 0;
  border: 0;
  user-select: none;

  &.active {
    background: #005bac;

    &:active {
      background: #014c8f;
    }
  }
}

.informed-person{
  margin: px2rem(24px) 0 0 px2rem(16px);
}

.informed-info{
  font-size: px2rem(14px);
  color: #333333;
  text-indent: 2em;
  margin: px2rem(16px) 0 0 px2rem(16px);
}