@function px2rem($px) {
  $rem: 14px;
  @return ($px/$rem)+rem;
}
html,body {
  font-family: PingFangSC-Regular, sans-serif;
}

.title-box {
  display: flex;
  width: px2rem(250px);
  height: px2rem(40px);
  font-size: px2rem(20px);
  font-weight: bold;
  padding: px2rem(20px) 0 0 0;
  margin: 0 auto;
  text-align: center;

  .title-line-left {
    width: px2rem(78px);
    margin-top: px2rem(15px);
    height: px2rem(1px);
    background: linear-gradient(90deg, rgba(70, 132, 205, 0) 0%, #4684CD 100%);
    border-radius: 4px;
  }

  .title-line-right {
    width: px2rem(78px);
    margin-top: px2rem(15px);
    height: px2rem(1px);
    background: linear-gradient(270deg, rgba(70, 132, 205, 0) 0%, #4684CD 100%);
    border-radius: 4px;
  }
  .title-text {
    width: px2rem(92px);
  }
}