@function px2rem($px) {
  $rem: 14px;
  @return ($px/$rem)+rem;
}
html,body {
  font-family: PingFangSC-Regular, sans-serif;
}
.service-page {
  width: 100%;
  background-color: #F2F3F4;
  padding: px2rem(15px);

  .service-head {
    width: 100%;
    display: flex;
    height: auto;
    .service-sale {
      flex: 1;
      width: 48%;
      height: 46%;
      margin-right: px2rem(5px);
      border-radius: px2rem(8px);
    }
    .service-slow-drugs {
      flex: 1;
      width: 48%;
      height: 46%;
      margin-left: 2%;
      border-radius: px2rem(8px);
    }
    .service-head-icon{
      width: px2rem(27px);
      height: px2rem(24px);
      background-color: #999999;
      margin: px2rem(13px) 0 0 px2rem(14px);
    }
    .service-head-text {
      margin: px2rem(7px) 0 0 px2rem(14px);
      font-size: px2rem(16px);
      display: inline-block;
      font-weight: bold;
      color: #ffffff;
    }
    .service-guide-icon {
      width: px2rem(14px);
      height: px2rem(14px);
      margin: 0 0 0 px2rem(5px);
      display: inline-block;
      background-color: #32343a;
    }
  }
  .service-object {
    width: 100%;
    display: flex;
    height: px2rem(86px);
    margin: px2rem(15px) 0;
    border-radius: px2rem(8px);
    background: linear-gradient(180deg, rgba(70, 132, 205, 0.39) 0%, rgba(255, 255, 255, 0) 33% , rgba(255, 255, 255, 1) 100%);
  }
}

.list-icon {
  width: px2rem(56px);
  height: px2rem(56px);
  margin: px2rem(15px) 0 0 px2rem(20px);
  border-radius: 50%;
}

.detail-list-icon {
  width: px2rem(42px);
  height: px2rem(42px);
  margin: px2rem(11px) 0 0 px2rem(20px);
  border-radius: 50%;
}

.list-container {
  width: px2rem(210px);
  margin: 21px;
  .list-title {
    width: px2rem(80px);
    height: px2rem(20px);
    font-size: 20px;
    font-weight: bold;
    color: #32343a;
  }
  .list-container-text {
    font-size: px2rem(14px);
    line-height: px2rem(16px);
    margin: px2rem(10px) 0 0 0;
  }
}

.service-container {
  width: 100%;
  border-radius: px2rem(8px);
  background-color: #ffffff;
  margin-bottom: px2rem(15px);
  padding-bottom: px2rem(20px);

  .container-detail-list {
    display: flex;
    width: 90%;
    height: px2rem(64px);
    margin: px2rem(20px) auto 0 auto;
    background-color: #F1F7FE;
    border-radius: px2rem(8px);

    .detail-list-text-one {
      width: 75%;
      line-height: px2rem(20px);
      vert-align: middle;
      margin: px2rem(24px) 0 0 px2rem(14px);
    }

    .detail-list-text-two {
      display: table-cell;
      width: 75%;
      line-height: px2rem(20px);
      vert-align: middle;
      margin: px2rem(12px) 0 0 px2rem(14px);
    }
  }



  .notice-list {
    width: 92%;
    margin: px2rem(12px) auto 0 auto;
    font-size: px2rem(14px);

    .notice-list-title {
      display: flex;
      font-weight: bold;

      .notice-list-dot {
        width: px2rem(4px);
        height: px2rem(4px);
        border-radius: px2rem(2px);
        background-color: #4684CD;
        margin: px2rem(8px) px2rem(5px) 0 0;
      }
    }
    .notice-list-test {
      font-size: px2rem(14px);
      margin-left: px2rem(9px);
    }
  }
}

.service-steps-box {
  margin-top: 20px;
  .service-steps {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10px;
    border-left: 1px dashed #EC952D;

    .service-step-number {
      position: absolute;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      margin: 0 0 0 px2rem(-11px);
      border-radius: 50%;
      width: px2rem(22px);
      height: px2rem(22px);
      background-color: #EC952D;
    }
    .service-step-text {
      width: 90%;
      font-size: px2rem(14px);
      margin-left: px2rem(22px);
    }

    .font-bold {
      font-weight: bold;
    }
  }

  .service-last-steps {
    width: 90%;
    margin: 0 auto;

    .service-step-number {
      position: absolute;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      margin: 0 0 0 px2rem(-11px);
      border-radius: 50%;
      width: px2rem(22px);
      height: px2rem(22px);
      background-color: #EC952D;
    }
    .service-step-text {
      width: 90%;
      font-size: px2rem(14px);
      margin-left: px2rem(22px);
    }
  }
}





